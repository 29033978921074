body { background-color: var(--clr-16631); }
body { color: var(--clr-16632); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-16632);
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-16632);
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-16632);
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
h4 {
color: var(--clr-16634);
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 22px;

}
}
h5 {
color: var(--clr-16632);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-16632);
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-16632);
font-family: 'PT Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 14;
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 22px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'PT Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'PT Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-16633);}
a:hover {color: var(--clr-16634);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-16634);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-16634);
color: #ffffff;
padding: 5px 15px;

 }
.MES3 {
background-color: var(--clr-16634);
color: #ffffff;
padding: 5px 15px;

h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
h1.MEC3 { font-size: 32.5px; }
h1.MEC3 { @media #{$medium-up} { font-size: 40px; }; }
h2.MEC3 { font-size: 28.6px; }
h2.MEC3 { @media #{$medium-up} { font-size: 35px; }; }
h3.MEC3 { font-size: 26px; }
h3.MEC3 { @media #{$medium-up} { font-size: 25px; }; }
h4.MEC3 { font-size: 26px; }
h4.MEC3 { @media #{$medium-up} { font-size: 20px; }; }
h5.MEC3 { font-size: 22.1px; }
h5.MEC3 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC3 { font-size: 20.8px; }
h6.MEC3 { @media #{$medium-up} { font-size: 18px; }; }
 }
a.MEC3 { color: #ffffff;
&:hover { color: #ffffff;}
 }
.MEC3 li {color: #ffffff;}
cite.MEC3{
color: #ffffff;
}
/* Shade 1:4 */
.MES4 {
background-color: var(--clr-16635);
color: var(--clr-16632);
 }
/* Shade 1:5 */
.MES5 {
background-color: var(--clr-16635);
color: var(--clr-16632);
 }
.MES5 {
background-color: var(--clr-16635);
color: var(--clr-16632);
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: var(--clr-16632);
 }
 }
cite.MEC5{
color: var(--clr-16632);
}
/* Home Block Wrap:6 */
.MES6 {
background-color: #ffffff;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES6 {
background-color: #ffffff;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
 }
/* Menu Bar:7 */
.MES7 {
background-color: var(--clr-16636);
 }
.MES7 {
background-color: var(--clr-16636);
 }
/* :8 */
nav.me-Menu.MES8 {
.menu-item.MEC8{ &:hover {background-color: var(--clr-16635);}
}
& .menu-item.MEC8, & .menu-item.MEC8 > div.MEC8{ & > a.MEC8{color: var(--clr-16632);
text-transform: uppercase;
}
 &:hover > a.MEC8{color: var(--clr-16632);
}
 }
&.horizontal > .menu-item.MEC8 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC8 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC8 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 15;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC8 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC8:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-16637-flat);}}
&.vertical .menu-item.MEC8:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-16637-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC8{background-color: var(--clr-16637); &:hover {background-color: 19;}
}
& .menu-item.MEC8, & .menu-item.MEC8 > div.MEC8{ & > a.MEC8{color: #ffffff;
}
 &:hover > a.MEC8{color: 15;
}
 }

}
}
 }
/* Content:9 */
.MES9 {
background-color: #ffffff;
color: var(--clr-16632);
padding: 20px 15px;

@media #{$medium-up} {
padding: 20px 30px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES9 {
background-color: #ffffff;
color: var(--clr-16632);
padding: 20px 15px;

@media #{$medium-up} {
padding: 20px 30px;

}
@media #{$large-up} {
padding: 20px 40px;

}
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-16634);
 }
 }
a.MEC9 { color: var(--clr-16634);
&:hover { color: var(--clr-16638);}
 }
.MEC9 li {color: var(--clr-16632-flat);}
cite.MEC9{
color: var(--clr-16632);
}
/* Dark:10 */
.MES10 {
background-color: var(--clr-16639);
color: #ffffff;
 }
/* Dark:11 */
.MES11 {
background-color: var(--clr-16639);
color: #ffffff;
font-size: 14.4px;
 }
.MES11 {
background-color: var(--clr-16639);
color: #ffffff;
font-size: 14.4px;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #ffffff;
 }
 }
a.MEC11 { color: #ffffff;
 }
cite.MEC11{
color: #ffffff;
font-size: 14.4px;
}
/* Parallax:12 */
.MES12 {
background-color: var(--clr-16639);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bookkeepingconcepts.com.au/img/489/25');
background-position: top center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES12 {
background-color: var(--clr-16639);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bookkeepingconcepts.com.au/img/489/25');
background-position: top center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: #ffffff;
 }
 }
cite.MEC12{
color: #ffffff;
}
/* Home Slider:13 */
.MES13 {
& .slider-arrow {color: var(--clr-16635);
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: #ffffff;
} }
/* home block:14 */
.MES14 {
background-color: var(--clr-16635);
color: var(--clr-16632);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 10px;

@media #{$large-up} {
padding: 15px 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-16635);
 }
.MES14 {
background-color: var(--clr-16635);
color: var(--clr-16632);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 10px;

@media #{$large-up} {
padding: 15px 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-16635);
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: var(--clr-16632);
 }
h1.MEC14 { font-size: 20px; }
h1.MEC14 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC14 { font-size: 17.6px; }
h2.MEC14 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC14 { font-size: 16px; }
h3.MEC14 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC14 { font-size: 16px; }
h4.MEC14 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC14 { font-size: 13.6px; }
h5.MEC14 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC14 { font-size: 12.8px; }
h6.MEC14 { @media #{$large-up} { font-size: 18px; }; }
 }
.MEC14 li {color: var(--clr-16632-flat);}
cite.MEC14{
color: var(--clr-16632);
}
/* Page separator:16 */
.MES16 {
& > hr {border-width: 1px;}
& > hr {border-color: var(--clr-16634) transparent transparent transparent;}
& > hr {border-top-style: dashed;}
& > hr {border-right-style: dashed;}
& > hr {border-bottom-style: dashed;}
& > hr {border-left-style: dashed;}
 }
/* Secondary:17 */
.MES17 {
background-color: var(--clr-16638);
color: var(--clr-16632);
 }
/* Secondary:18 */
.MES18 {
background-color: var(--clr-16638);
color: #ffffff;
 }
.MES18 {
background-color: var(--clr-16638);
color: #ffffff;
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: #ffffff;
 }
 }
cite.MEC18{
color: #ffffff;
}
/* Alternate:19 */
.MES19 {
background-color: var(--clr-16637);
color: var(--clr-16632);
 }
/* Alternate:20 */
.MES20 {
background-color: var(--clr-16637);
color: #ffffff;
 }
.MES20 {
background-color: var(--clr-16637);
color: #ffffff;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
.MEC20 li {color: #ffffff;}
cite.MEC20{
color: #ffffff;
}
/* Light:21 */
.MES21 {
background-color: var(--clr-16633);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Light:22 */
.MES22 {
background-color: var(--clr-16633);
color: #ffffff;
 }
.MES22 {
background-color: var(--clr-16633);
color: #ffffff;
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: #ffffff;
 }
 }
cite.MEC22{
color: #ffffff;
}
/* Shade 2:23 */
.MES23 {
background-color: var(--clr-16641);
 }
/* Shade 2:24 */
.MES24 {
background-color: var(--clr-16641);
 }
.MES24 {
background-color: var(--clr-16641);
 }
/* icon colour:25 */
.MES25 {
color: var(--clr-16634);
 }
.MES25 {
color: var(--clr-16634);
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: var(--clr-16634);
 }
 }
cite.MEC25{
color: var(--clr-16634);
}
/* Slider box:26 */
.MES26 {
background-color: var(--clr-16642);
color: var(--clr-16639);
 }
.MES26 {
background-color: var(--clr-16642);
color: var(--clr-16639);
 }
cite.MEC26{
color: var(--clr-16639);
}
/* Faq:27 */
details.MES27 {
& > summary {border-radius: 3px;}
& > summary{padding: 15px;}

& > summary{border-width: 1px;
border-style:solid;
border-color: var(--clr-16634);
}& > summary {& > div  > i{color: var(--clr-16634);
font-size: 14px;
}}
 }
/* Services Panel:28 */
.MES28 {
padding: 30px;

 }
.MES28 {
padding: 30px;

 }
/* responsive Menu:29 */
nav.responsive-menu {
.menu-item.MEC29{background-color: var(--clr-16636);}
& .menu-item.MEC29, & .menu-item.MEC29 > div.menu-item-wrap{ & > a.MEC29, & > i{color: var(--clr-16639);
text-align: left;
}
  }
& .menu-item.MEC29 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px 30px;}

& .sub-menu{ .sub-menu {}}

 }
/* Free Consultation Popup:30 */
.MES30 {
background-color: var(--clr-16634);
color: #ffffff;
padding: 5px 15px;

 }
.MES30 {
background-color: var(--clr-16634);
color: #ffffff;
padding: 5px 15px;

h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
a.MEC30 { color: #ffffff;
&:hover { color: #ffffff;}
 }
.MEC30 li {color: #ffffff;}
cite.MEC30{
color: #ffffff;
}
/* Blue Button:31 */
.MES31 {
background-color: var(--clr-16634);
&:hover {background-color: var(--clr-16633);}
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* News Block:32 */
.MES32 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-16631);
 }
.MES32 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-16631);
 }
/* spacer:34 */
.MES34 {
padding: 0;

@media #{$medium-up} {
padding: 15px 0;

}
 }
/* Pink Button:35 */
.MES35 {
background-color: var(--clr-16633);
&:hover {background-color: var(--clr-16634);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 15.4px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 20.8px;
};
padding: 20px 10px;

 }
/* Shade 3:36 */
.MES36 {
background-color: #ffffff;
 }
/* Shade 3:37 */
.MES37 {
background-color: #ffffff;
 }
.MES37 {
background-color: #ffffff;
 }
